import {
  MobileViewCalender,
  SalahTimings,
} from "@msa-software-llc/connectmazjid-vanilaportal";
import React from "react";
import "./SalahTiming.css"; // Import CSS file for styling
import { Masjid } from "../../../../Types";
import SalahTimingIframe from "../../../Components/SalahTimingIframe/SalahTimingIframe";

interface SalahTimingProps {
  currentMasjid: Masjid | null;
  consumerMasjidId: string;
}
const SalahTiming: React.FC<SalahTimingProps> = ({
  currentMasjid,
  consumerMasjidId,
}) => {
  // const loggedInUserInfo = adminFromLocalStg();
  // const consumerMasjidId = loggedInUserInfo.masjids[0];
  const webLink = currentMasjid?.externalLinks?.find(
    (link) => link.name === "Website"
  );

  return (
    <>
      {/* {webLink ? (
        <div className="salah-timing-container">
          <div className="iframe-container-1">
            <iframe
              style={{ width: "100%" }}
              src={webLink?.url}
              title="Iframe"
            />
          </div>

          <SalahTimingIframe />
        </div>
      ) : ( */}
      <SalahTimings consumerMasjidId={consumerMasjidId} />
      {/* <MobileViewCalender consumerMasjidId={consumerMasjidId} /> */}
      {/* )} */}
    </>
  );
};

export default SalahTiming;
