import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import customScrollarStyles from "../../Shared/CustomScrollBar.module.css";
import {
  dummyExternalData,
  useAdminLedgers,
  useGetAdminById,
} from "../../../graphql/Admins/query";
import {
  AdminStatus,
  getAdminStatus,
  getFormattedAdminStatus,
} from "../helpers";
import { roleRenamer } from "../../../helpers/RoleRenamer";
import moment from "moment";
import StatusLogs from "../../Shared/StatusLogs/StatusLogs";
import approvedBtnTick from "../../../Photos/Newuiphotos/VerificationCenter/approvedBtnTick.webp";
import rejectedBtnCross from "../../../Photos/Newuiphotos/VerificationCenter/rejectedBtnCross.webp";

import ReasonConfirmation from "./ReasonConfirmation";
import toast from "react-hot-toast";
import { DeleteMasjidInAdminById } from "../../../Redux/Actions/AdminActions/DeleteMasjidInAdminById";
import { FreezeAdmin } from "../../../Redux/Actions/AdminActions/FreezingAdmin";
import { ChangeSnackbar } from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import { useAppThunkDispatch } from "../../../Redux/hooks";
import ExternalAdminHandler1 from "./ExternalAdminHandler1";
import ExternalAdminHandler2 from "./ExternalAdminHandler2";
import InternalAdminForm from "../InternalAdmins/InternalAdminForm";
import { UpdatingAdminById } from "../../../Redux/Actions/AdminActions/UpdatingAdminById";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_USER_STATUS } from "../../../graphql/Admins/mutation";
import { UpdateMasjidInAdminById } from "../../../Redux/Actions/AdminActions/UpdatingAdminMasjidById";
import { GET_MASJIDS } from "../../../graphql/queries";
const ValueFieldStyles = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  color: "#1B8368",
  fontWeight: "bold",
};
const getValueFieldStyles = (value: any) => {
  if (value) {
    return ValueFieldStyles;
  } else {
    return { ...ValueFieldStyles, color: "#FF4949" };
  }
};
const ExternalAdminStatushandler = ({
  cooldowns,
  setCooldowns,
  selectedAdmin,
  setSelectedAdmin,
  open,
  setOpen,
  onClose,
  isLoading,
  refetch,
}: {
  cooldowns: { [key: string]: number };
  setCooldowns: React.Dispatch<React.SetStateAction<{ [key: string]: number }>>;
  selectedAdmin: any;
  setSelectedAdmin: (admin: any) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose: () => void;
  isLoading: boolean;
  refetch: () => void;
}) => {
  const [showLogs, setShowLogs] = useState(false);
  const [showReasonConfirmation, setShowReasonConfirmation] = useState(false);
  const [skipfetchLogs, setSkipfetchLogs] = useState(true);
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(false);
  const [skipfetchAdmin, setSkipfetchAdmin] = useState(true);
  const [newStatus, setNewStatus] = useState("");
  const [showFreezeWarning, setShowFreezeWarning] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const {
    loading: loadingLedgers,
    error: ledgerError,
    data: logs,
    refetch: refetchLogs,
  } = useAdminLedgers(
    selectedAdmin?._id ?? "",
    !selectedAdmin?._id || !showLogs
  );
  const {
    loading: loadingMasjids,
    error: errorMasjids,
    data: dataMasjids,
    refetch: refetchMasjids,
  } = useQuery(GET_MASJIDS, {
    variables: { limit: 0, page: 0 },
    fetchPolicy: "network-only",
  });
  const [updateUserStatus, { data, loading, error }] =
    useMutation(UPDATE_USER_STATUS);

  // const modifyAdminStatus = (admin: any, action: string) => {};

  const dispatch = useAppThunkDispatch();
  useEffect(() => {
    if (errorMasjids) {
      toast.error("Error Fetching Masjids");
    }
  }, [loadingMasjids, dataMasjids, errorMasjids]);
  // const handleSubmit = async (status: string) => {
  //   setNewStatus(status);
  //   if (!reason || !status || !selectedAdmin?._id) return;
  //   const freeze = status === "approved" ? false : true;
  //   if (freeze) {
  //     handleFreezeAdmin();
  //   }
  //   // try {
  //   //   toast.success("Successfully Updated Status");
  //   // setShowReasonConfirmation(false);
  //   // setReason("");
  //   // setReasonError(false);
  //   // setSkipfetchAdmin(false);
  //   // refetch();
  //   // refetchLogs();
  //   // } catch (err) {
  //   // console.error("Error submitting form:", err);
  //   // toast.error("Error Updating Admin Status");
  //   // }
  // };
  // const handleFreezeAdmin = async () => {
  //   let formData = {
  //     userId: selectedAdmin?._id,
  //     isFreeze: true,
  //   };

  //   const isUserDeleted = await dispatch(
  //     DeleteMasjidInAdminById({ user: selectedAdmin?._id ?? "" })
  //   );

  //   if (isUserDeleted.success) {
  //     const response = await dispatch(FreezeAdmin(formData));
  //     if (response.success) {
  //       const snackbarSuccessDetails = {
  //         snackbarOpen: true,
  //         snackbarType: "success",
  //         snackbarMessage: "Freezed The Admin Successfully",
  //       };
  //       dispatch(ChangeSnackbar(snackbarSuccessDetails));
  //     } else {
  //       const snackbarFailureDetails = {
  //         snackbarOpen: true,
  //         snackbarType: "error",
  //         snackbarMessage:
  //           "Couldn't update the Admin Profile: " + response.message,
  //       };
  //       dispatch(ChangeSnackbar(snackbarFailureDetails));
  //     }
  //   } else {
  //     // Handle the case where the first dispatch does not return true
  //     const snackbarFailureDetails = {
  //       snackbarOpen: true,
  //       snackbarType: "error",
  //       snackbarMessage: "Failed to remove the masjid",
  //     };
  //     dispatch(ChangeSnackbar(snackbarFailureDetails));
  //   }
  // };
  // useEffect(() => {
  //   if (logs && !ledgerError && !loadingLedgers) {
  //     setSkipfetchLogs(true);
  //   } else if (ledgerError) {
  //     setSkipfetchLogs(true);
  //   }
  // }, [loadingLedgers, ledgerError, logs]);
  const handleUpdateAdmin = (
    name: string,
    email: string,
    role: string,
    selectedMasjid: string
  ) => {
    let updatedData = {
      name: name,
      email: email,
      role: role,
    };
    const response = dispatch(
      UpdatingAdminById(updatedData, selectedAdmin?._id ?? "")
    );
    response.then(function (result: any) {
      if (result.success) {
        setSelectedAdmin(result.data);
        setIsEditing(false);
        refetch();
      } else {
        const snackbarFailureDetails = {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: result.message
            ? "Failed to Load Admin Details : " + result.message
            : "Failed to Load Masjid Details : Internet or Server Issue ",
        };
        dispatch(ChangeSnackbar(snackbarFailureDetails));
      }
    });
  };
  const adminStatus = getAdminStatus(
    selectedAdmin?.isFreezed,
    selectedAdmin?.isVerified
  );

  // const handleAssignFreezeConfirmation = () => {
  //   setShowReasonConfirmation(true);
  // };

  const handleClose = () => {
    if (showReasonConfirmation) {
      setShowReasonConfirmation(false);
      setReason("");
      setReasonError(false);
    } else if (showLogs) {
      setShowLogs(false);
    } else {
      setOpen(false);
    }
  };
  const handleAction = (NewStatus: string) => {
    setNewStatus(NewStatus);
    setShowReasonConfirmation(true);
  };
  const handleCloseButton = () => {
    if (showReasonConfirmation) {
      setShowReasonConfirmation(false);
      setReason("");
      setReasonError(false);
    } else if (showLogs) {
      setShowLogs(false);
    } else {
      setOpen(false);
      onClose();
    }
  };
  const handleConfirmation = async (status: string) => {
    console.log(status);
    if (!selectedAdmin?._id) {
      toast.error("Admin not found");
      return;
    }
    let toastid = toast.loading("Updating Status...");

    // else if (status === "rejected") {
    //   handleFreezeAdmin();
    //   return;
    // }
    const freeze = status === "rejected";
    try {
      const response = await updateUserStatus({
        variables: {
          input: {
            userId: selectedAdmin._id,
            description: reason,
            status: status,
          },
          freeze: freeze,
        },
      });
      toast.dismiss();
      toast.success("Successfully Updated Admin Status");
      setShowReasonConfirmation(false);
      setOpen(false);
      refetch();
      console.log("Mutation response:", response.data);
    } catch (err) {
      toast.dismiss();

      toast.error("Error Updating Admin Status");
      console.error("Error updating user status:", err);
    }
  };
  const handleFreezeAdmin = async () => {
    let formData = {
      userId: selectedAdmin?._id,
      isFreeze: true,
    };

    const isUserDeleted = await dispatch(
      DeleteMasjidInAdminById({ user: selectedAdmin?._id ?? "" })
    );

    if (isUserDeleted.success) {
      const response = await dispatch(FreezeAdmin(formData));
      if (response.success) {
        toast.success("Successfully Updated Admin Status");
        setShowReasonConfirmation(false);
        setOpen(false);
        refetch();
      } else {
        toast.error("Error Updating Admin Status");
      }
    } else {
      // Handle the case where the first dispatch does not return true
      toast.error("Error Updating Admin Status");
    }
  };
  return (
    <>
      <InternalAdminForm
        open={isEditing}
        setOpen={setIsEditing}
        onClose={() => {
          setIsEditing(false);
        }}
        handleSubmit={handleUpdateAdmin}
        existingDetails={{
          name: selectedAdmin?.name ?? "",
          email: selectedAdmin?.email ?? "",
          role: selectedAdmin?.role ?? "",
          masjid:
            selectedAdmin?.masjidDetails &&
            Array.isArray(selectedAdmin.masjidDetails) &&
            selectedAdmin?.masjidDetails.length > 0
              ? selectedAdmin?.masjidDetails[0]._id
              : "",
        }}
        isEditMode={true}
        adminType="external"
      />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "22px",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            borderBottom: "1px solid #ccc",
            color: "#3D5347",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          {showLogs
            ? "Logs"
            : showReasonConfirmation
            ? "Reason"
            : "Admin Details"}
          <IconButton
            aria-label="close"
            onClick={handleCloseButton}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className={customScrollarStyles["custom-scrollbar"]}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent:
              isLoading || (showLogs && logs?.length <= 0)
                ? "center"
                : "flex-start",
            overflowX: "hidden",
            minHeight: showLogs && logs?.length > 0 ? "auto" : "400px",
            height: "100%",
          }}
        >
          {selectedAdmin?.isAddedFromAdmin ? (
            // {true ? (
            <ExternalAdminHandler2
              setCooldowns={setCooldowns}
              cooldowns={cooldowns}
              isLoading={isLoading || loadingLedgers}
              // isLoading={true}
              showReasonConfirmation={showReasonConfirmation}
              setShowReasonConfirmation={setShowReasonConfirmation}
              handleSubmit={handleConfirmation}
              adminStatus={adminStatus}
              logs={logs}
              reason={reason}
              setReason={setReason}
              reasonError={reasonError}
              setReasonError={setReasonError}
              selectedAdmin={selectedAdmin}
              showLogs={showLogs}
              setShowLogs={setShowLogs}
              handleAction={handleAction}
              setIsEditing={setIsEditing}
              isEditing={isEditing}
              refetch={refetch}
              loadingMasjids={loadingMasjids}
              dataMasjids={dataMasjids}
              errorMasjids={errorMasjids}
              setOpen={setOpen}
            ></ExternalAdminHandler2>
          ) : (
            <>
              <ExternalAdminHandler1
                isLoading={isLoading || loadingLedgers}
                showReasonConfirmation={showReasonConfirmation}
                handleSubmit={handleConfirmation}
                adminStatus={adminStatus}
                logs={logs}
                reason={reason}
                setReason={setReason}
                reasonError={reasonError}
                setReasonError={setReasonError}
                selectedAdmin={selectedAdmin}
                showLogs={showLogs}
                setShowLogs={setShowLogs}
                handleAction={handleAction}
                newStatus={newStatus}
                refetch={refetch}
                loadingMasjids={loadingMasjids}
                dataMasjids={dataMasjids}
                errorMasjids={errorMasjids}
                setOpen={setOpen}
              ></ExternalAdminHandler1>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ExternalAdminStatushandler;
