import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import pendingIcon from "../../../Photos/Newuiphotos/VerificationCenter/pendingIcon.webp";
import approvedIcon from "../../../Photos/Newuiphotos/VerificationCenter/approvedIcon.webp";
import deniedIcon from "../../../Photos/Newuiphotos/VerificationCenter/deniedIcon.webp";
import exclamationIcon from "../../../Photos/Newuiphotos/VerificationCenter/exclamationIcon.webp";
import approvedBtnTick from "../../../Photos/Newuiphotos/VerificationCenter/approvedBtnTick.webp";
import rejectedBtnCross from "../../../Photos/Newuiphotos/VerificationCenter/rejectedBtnCross.webp";
import "./VerificationCenterTable.css";
import RequestActionModal from "../Modal/RequestActionModal";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ACCOUNTDETAILS,
  GET_LOGS,
  GET_STRIPEACCOUNTS,
} from "../../../graphql/VerificationCenter/queries";
import { UPDATE_STATUS } from "../../../graphql/VerificationCenter/mutation";
import toast from "react-hot-toast";
const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .no-rows-primary": {
    fill: "#3D4751",
    ...theme.applyStyles("light", {
      fill: "#AEB8C2",
    }),
  },
  "& .no-rows-secondary": {
    fill: "#1D2126",
    ...theme.applyStyles("light", {
      fill: "#E8EAED",
    }),
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={96}
        viewBox="0 0 452 257"
        aria-hidden
        focusable="false"
      >
        <path
          className="no-rows-primary"
          d="M348 69c-46.392 0-84 37.608-84 84s37.608 84 84 84 84-37.608 84-84-37.608-84-84-84Zm-104 84c0-57.438 46.562-104 104-104s104 46.562 104 104-46.562 104-104 104-104-46.562-104-104Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 113.929c3.905-3.905 10.237-3.905 14.142 0l63.64 63.64c3.905 3.905 3.905 10.236 0 14.142-3.906 3.905-10.237 3.905-14.142 0l-63.64-63.64c-3.905-3.905-3.905-10.237 0-14.142Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 191.711c-3.905-3.906-3.905-10.237 0-14.142l63.64-63.64c3.905-3.905 10.236-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142l-63.64 63.64c-3.905 3.905-10.237 3.905-14.142 0Z"
        />
        <path
          className="no-rows-secondary"
          d="M0 10C0 4.477 4.477 0 10 0h380c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 20 0 15.523 0 10ZM0 59c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 69 0 64.523 0 59ZM0 106c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 153c0-5.523 4.477-10 10-10h195.5c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 200c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 247c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10Z"
        />
      </svg>
      <Box sx={{ mt: 2 }}>No rows</Box>
    </StyledGridOverlay>
  );
}
// const data = [
//   {
//     id: 1,
//     email: "asifmaxwellkhan@gmail.com",
//     masjidName: "East Plano Islamic Center (EPIC)",
//     adminName: "Aasif Khan",
//     businessName: "East Plano Islamic Center (EPIC)",
//     adminEmail: "asifmaxwellkhan@gmail.com",
//     stripeEmail: "asifmaxwellkhan@gmail.com",
//     stripeAccountName: "Aasif Khan",
//     contactNumber: "28926382222",
//     status: "rejected",
//     lastUpdatedBy: "Mustafa",
//     lastUpdatedOn: "04 Aug, 2024 | 05:00 PM",
//   },
//   {
//     id: 2,
//     email: "noor.m@gmail.com",
//     masjidName: "Islamic Center of Frisco",
//     adminName: "Noor M.",
//     businessName: "East Plano Islamic Center (EPIC)",
//     adminEmail: "noor.m@gmail.com",
//     stripeEmail: "noor.m@gmail.com",
//     stripeAccountName: "Noor M.",
//     contactNumber: "28926382222",
//     status: "approved",
//     lastUpdatedBy: "Mustafa",
//     lastUpdatedOn: "04 Aug, 2024 | 05:00 PM",
//   },
//   {
//     id: 3,
//     email: "bashir@gmail.com",
//     masjidName: "Huda Masjid",
//     adminName: "Bashir",
//     businessName: "East Plano Islamic Center (EPIC)",
//     adminEmail: "bashir@gmail.com",
//     stripeEmail: "bashir@gmail.com",
//     stripeAccountName: "Bashir",
//     contactNumber: "28926382222",
//     status: "rejected",
//     lastUpdatedBy: "Mustafa",
//     lastUpdatedOn: "04 Aug, 2024 | 05:00 PM",
//   },
//   {
//     id: 4,
//     email: "riyad@gmail.com",
//     masjidName: "Islamic Center of Mckineey",
//     adminName: "Riyad",
//     businessName: "East Plano Islamic Center (EPIC)",
//     adminEmail: "riyad@gmail.com",
//     stripeEmail: "riyad@gmail.com",
//     stripeAccountName: "Riyad",
//     contactNumber: "28926382222",
//     status: "approved",
//     lastUpdatedBy: "Mustafa",
//     lastUpdatedOn: "04 Aug, 2024 | 05:00 PM",
//   },
//   {
//     id: 5,
//     email: "bibek@gmail.com",
//     masjidName: "Tehsil Masjid Nagina",
//     adminName: "Bibek",
//     businessName: "East Plano Islamic Center (EPIC)",
//     adminEmail: "bibek@gmail.com",
//     stripeEmail: "bibek@gmail.com",
//     stripeAccountName: "Bibek",
//     contactNumber: "28926382222",
//     status: "pending",
//     lastUpdatedBy: "Mustafa",
//     lastUpdatedOn: "04 Aug, 2024 | 05:00 PM",
//   },
// ];

// Define columns for DataGrid
const columns = [
  { field: "email", headerName: "Account Email", flex: 1 },
  { field: "masjidName", headerName: "Masjid Name", flex: 1 },
  { field: "adminName", headerName: "Admin Name", flex: 1 },
  {
    field: "action",
    headerName: "Action",
    flex: 1,
    renderCell: (params) => {
      const status = params.row.status; // Get status of the row
      let buttonText = "Required Action";
      let buttonColor = "#1B8368"; // Default color for "pending"
      let buttonIcon = exclamationIcon;
      let IconSize = "16px";
      if (status === "approved") {
        buttonText = "Approved";
        buttonIcon = approvedBtnTick; // Use your approved icon
        buttonColor = "#00D23B"; // Green for approved
        IconSize = "16px";
      } else if (status === "rejected") {
        buttonText = "Rejected";
        buttonIcon = rejectedBtnCross; // Use your rejected icon
        buttonColor = "#F93737"; // Red for rejected
        IconSize = "11px";
      }

      return (
        <Button
          variant="contained"
          sx={{
            bgcolor: buttonColor,
            lineHeight: "1.60",
            textTransform: "none",
            borderRadius: "10px",
            "&:hover": { bgcolor: buttonColor },
          }}
        >
          <Box
            component="img"
            src={buttonIcon}
            width={IconSize}
            marginRight="5px"
          />
          {buttonText}
        </Button>
      );
    },
  },
];

const VerificationCenterTable = () => {
  const [selectedStatus, setSelectedStatus] = useState("pending");
  const [searchText, setSearchText] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDetails, setModalDetails] = useState();
  const [logDetails, setLogDetails] = useState();
  const [actionType, setActionType] = useState("");
  const [approved, setApproved] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [pending, setPending] = useState([]);
  const [selectedmasjidId, setSelectedmasjidId] = useState("");
  const [skipAccountDetails, setSkipAccountDetails] = useState(true);
  const [skipLogs, setSkipLogs] = useState(true);

  const [
    updateStatus,
    {
      loading: loadingUpdateStatus,
      error: statusUpdateError,
      data: statusUpdateData,
    },
  ] = useMutation(UPDATE_STATUS);

  const { loading, error, data, refetch } = useQuery(GET_STRIPEACCOUNTS, {
    fetchPolicy: "network-only",
  });
  // const {
  //   loading: loading1,
  //   error: error1,
  //   data: data1,
  //   refetch: refetch1,
  // } = useQuery(GET_STRIPEACCOUNTS);
  // const {
  //   loading: loading2,
  //   error: error2,
  //   data: data2,
  //   refetch: refetch2,
  // } = useQuery(GET_STRIPEACCOUNTS, {
  //   variables: { status: "rejected" },
  // });

  const {
    loading: accountDetailsLoading,
    error: accountDetailsError,
    data: accountData,
    // refetch: accountRefetch,
  } = useQuery(GET_ACCOUNTDETAILS, {
    variables: { masjidId: selectedmasjidId },
    skip: skipAccountDetails,
    fetchPolicy: "no-cache",
  });
  const {
    loading: logsLoading,
    error: logsError,
    data: logsData,
    // refetch: accountRefetch,
  } = useQuery(GET_LOGS, {
    variables: { masjidId: selectedmasjidId },
    skip: skipLogs,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (accountData?.accountDetails && !accountDetailsLoading) {
      setSkipAccountDetails(true);
      setModalDetails(accountData.accountDetails);
    }
  }, [accountData, accountDetailsLoading]);

  useEffect(() => {
    if (logsData?.approvalLedgers && !logsLoading) {
      setSkipLogs(true);
      setLogDetails(logsData.approvalLedgers);
      // setModalOpen(true);
    }
  }, [logsData, logsLoading]);

  useEffect(() => {
    if (data?.stripeAccounts && !loading) {
      const { approved, rejected, pending } = data.stripeAccounts.reduce(
        (acc, account) => {
          if (account.status === "approved") {
            acc.approved.push(account);
          } else if (account.status === "rejected") {
            acc.rejected.push(account);
          } else if (
            account.status === "pending" ||
            account.status === "inreview"
          ) {
            acc.pending.push(account);
          }
          return acc;
        },
        { approved: [], rejected: [], pending: [] }
      );

      setApproved(approved);
      setRejected(rejected);
      setPending(pending);
    }
  }, [data, loading]);

  // useEffect(() => {
  //   if (data1?.stripeAccounts && !loading1) {
  //     setApproved(data1.stripeAccounts);
  //   }
  // }, [data1, loading1]);
  // useEffect(() => {
  //   if (data2?.stripeAccounts && !loading2) {
  //     setRejected(data2.stripeAccounts);
  //   }
  // }, [data2, loading2]);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleReloadTable = () => {
    refetch();
    // refetch1();
    // refetch2();
  };

  const handleReloadModal = (action: string) => {
    // accountRefetch();
    setModalOpen(true);
    setSkipAccountDetails(false);
    if (action !== "pending") setSkipLogs(false);
  };
  const selectedData =
    selectedStatus === "pending"
      ? pending
      : selectedStatus === "approved"
      ? approved
      : rejected;

  const filteredData =
    selectedData.length > 0
      ? selectedData.filter(
          (row) =>
            // row.status === selectedStatus &&
            row.adminEmail.toLowerCase().includes(searchText.toLowerCase()) ||
            row.masjidName.toLowerCase().includes(searchText.toLowerCase()) ||
            row.adminName.toLowerCase().includes(searchText.toLowerCase())
        )
      : [];
  const handleActionClick = (row: any, actionType: string) => {
    // setModalDetails(row);
    setSelectedmasjidId(row.masjidId);
    setModalOpen(true);
    setSkipAccountDetails(false);
    if (actionType !== "pending") setSkipLogs(false);
  };

  // Function to handle modal confirmation
  const handleConfirmAction = (action: string, reason: string) => {
    const toastId = toast.loading("Updating Status. Please wait...");
    updateStatus({
      variables: {
        masjidId: modalDetails?.masjidId ?? "",
        input: {
          status: action,
          ledger: {
            message: reason,
            status: action,
          },
        },
      },
    })
      .then((response) => {
        toast.dismiss(toastId);
        handleReloadTable();
        setSelectedStatus(action);
        handleReloadModal(action);
        toast.success("Successfully Updated Status");
      })
      .catch((error) => {
        toast.dismiss(toastId);
        toast.error("Error Updating Status");
        setTimeout(() => {
          toast.dismiss();
        }, 2000);
        console.error("Error updating member:", error);
      });
    // Add logic to update the request status or perform other actions
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: 3,
      }}
    >
      <Grid container spacing={3}>
        {/* Status Cards */}
        <Grid item xs={4} onClick={() => setSelectedStatus("pending")}>
          <Card
            sx={{
              borderRadius: "22px",
              border:
                selectedStatus === "pending" ? "2px solid #1B8368" : "none",
              boxShadow:
                selectedStatus === "pending"
                  ? "none"
                  : "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                color="#1B8368"
                sx={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <Box
                  component="img"
                  src={pendingIcon}
                  alt="pending"
                  sx={{ width: 28 }}
                />
                Pending
              </Typography>
              <Typography
                variant="h5"
                color="#1B8368"
                sx={{ fontWeight: "bold" }}
              >
                {pending.length.toString().padStart(2, "0")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4} onClick={() => setSelectedStatus("approved")}>
          <Card
            sx={{
              borderRadius: "22px",
              border:
                selectedStatus === "approved" ? "2px solid #1B8368" : "none",
              boxShadow:
                selectedStatus === "approved"
                  ? "none"
                  : "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                color="#00D23B"
                sx={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <Box
                  component="img"
                  src={approvedIcon}
                  alt="approved"
                  sx={{ width: 28 }}
                />
                Approved
              </Typography>
              <Typography
                variant="h5"
                color="#00D23B"
                sx={{ fontWeight: "bold" }}
              >
                {approved.length.toString().padStart(2, "0")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4} onClick={() => setSelectedStatus("rejected")}>
          <Card
            sx={{
              borderRadius: "22px",
              border:
                selectedStatus === "rejected" ? "2px solid #1B8368" : "none",
              boxShadow:
                selectedStatus === "rejected"
                  ? "none"
                  : "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                color="#F93737"
                sx={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <Box
                  component="img"
                  src={deniedIcon}
                  alt="rejected"
                  sx={{ width: 28 }}
                />
                Rejected
              </Typography>
              <Typography
                variant="h5"
                color="#F93737"
                sx={{ fontWeight: "bold" }}
              >
                {rejected.length.toString().padStart(2, "0")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Search and DataGrid Table */}
      <Card sx={{ mt: 3 }}>
        <TextField
          fullWidth
          placeholder="Search"
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon sx={{ fontSize: "25px" }} />
              </IconButton>
            ),
          }}
          sx={{
            margin: "20px",
            width: "95%",
            borderRadius: "12px", // Add this line to increase the border radius
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Add shadow here

            "& .MuiOutlinedInput-root": {
              borderRadius: "12px", // Ensure the input field's border radius is updated
            },
          }}
          variant="outlined"
        />

        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            loading={loading}
            rows={filteredData}
            columns={[
              { field: "adminEmail", headerName: "Account Email", flex: 1 },
              { field: "masjidName", headerName: "Masjid Name", flex: 1 },
              { field: "adminName", headerName: "Admin Name", flex: 1 },
              {
                field: "action",
                headerName: "Action",
                flex: 1,
                renderCell: (params) => {
                  const status = params.row.status;
                  let buttonText = "Required Action";
                  let buttonColor = "#1B8368"; // Default color
                  let buttonIcon = exclamationIcon;
                  let IconSize = "16px";
                  if (status === "approved") {
                    buttonText = "Approved";
                    buttonIcon = approvedBtnTick; // Use your approved icon
                    buttonColor = "#00D23B"; // Green for approved
                    IconSize = "16px";
                  } else if (status === "rejected") {
                    buttonText = "Rejected";
                    buttonIcon = rejectedBtnCross; // Use your rejected icon
                    buttonColor = "#F93737"; // Red for rejected
                    IconSize = "11px";
                  }

                  return (
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: buttonColor,
                        lineHeight: "1.60",
                        textTransform: "none",
                        borderRadius: "10px",
                        "&:hover": { bgcolor: buttonColor },
                      }}
                      onClick={() => handleActionClick(params.row, status)}
                    >
                      <Box
                        component="img"
                        src={buttonIcon}
                        width={IconSize}
                        marginRight="5px"
                      />
                      {buttonText}
                    </Button>
                  );
                },
              },
            ]}
            pageSize={10}
            rowsPerPageOptions={[10]}
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            disableSelectionOnClick
            getRowId={(row) => row._id}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? "even-row"
                : "odd-row"
            }
            sx={{
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-cell": {
                "&:hover": {
                  outline: "none",
                },
                userSelect: "text",
              },
            }}
          />
        </div>
      </Card>
      <RequestActionModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        requestDetails={modalDetails}
        logDetails={logDetails}
        onConfirmAction={handleConfirmAction}
        isLoading={accountDetailsLoading}
      />
    </Box>
  );
};

export default VerificationCenterTable;
