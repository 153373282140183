import {
  Box,
  Button,
  CircularProgress,
  debounce,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
// import ReasonConfirmation from "../InternalAdmins/ReasonConfirmation";
import { AdminStatus, getAdminStatus } from "../helpers";
import { roleRenamer } from "../../../helpers/RoleRenamer";
import moment from "moment";
import toast from "react-hot-toast";
import EditIcon from "../../../Photos/Newuiphotos/Icons/el_edit.png";
import Dropdown from "../InternalAdmins/customDropdown/CustomDropdown";
import ChangeIcon from "../../../Photos/Newuiphotos/Admins/exchange.png";
import { GET_MASJIDS } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";
import { Masjid } from "../../../../Types";
import { set } from "cypress/types/lodash";
import { useAppThunkDispatch } from "../../../Redux/hooks";
import { ChangeSnackbar } from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import { UpdateMasjidInAdminById } from "../../../Redux/Actions/AdminActions/UpdatingAdminMasjidById";
import ReasonConfirmation from "./ReasonConfirmation";
import { ReSendingReinvitation } from "../../../Redux/Actions/AuthActions/ResendingInvitation";
import { DeleteMasjidInAdminById } from "../../../Redux/Actions/AdminActions/DeleteMasjidInAdminById";
import EmailFilledIcon from "../../../Photos/Newuiphotos/Admins/EmailFilledIcon.png";
import StatusLogs from "../StatusLogs/StatusLogs";
import { useSearchMasjids } from "../../../graphql/Masjids/query";
import SearchDropdown from "../SearchDropdown/SearchDropdown";
import SearchDropdownChange from "./SearchMasjidChange";

const ValueFieldStyles = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  color: "#1B8368",
  fontWeight: "bold",
};
const getValueFieldStyles = (value: any) => {
  if (value) {
    return ValueFieldStyles;
  } else {
    return { ...ValueFieldStyles, color: "#FF4949" };
  }
};
const ExternalAdminHandler2 = ({
  cooldowns,
  setCooldowns,
  isLoading,
  showReasonConfirmation,
  setShowReasonConfirmation,
  handleSubmit,
  adminStatus,
  reason,
  setReason,
  reasonError,
  setReasonError,
  selectedAdmin,
  showLogs,
  setShowLogs,
  logs,
  handleAction,
  isEditing,
  setIsEditing,
  refetch,
  loadingMasjids,
  dataMasjids,
  errorMasjids,
  setOpen,
}: {
  cooldowns: { [key: string]: number };
  setCooldowns: React.Dispatch<React.SetStateAction<{ [key: string]: number }>>;
  isLoading: boolean;
  showReasonConfirmation: boolean;
  setShowReasonConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (status: string) => void;
  adminStatus: string;
  reason: string;
  setReason: React.Dispatch<React.SetStateAction<string>>;
  reasonError: boolean;
  setReasonError: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAdmin: any;
  showLogs: boolean;
  setShowLogs: React.Dispatch<React.SetStateAction<boolean>>;
  logs: any;
  handleAction: (status: string) => void;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: () => void;
  loadingMasjids: boolean;
  dataMasjids: any;
  errorMasjids: any;
  setOpen: (open: boolean) => void;
}) => {
  const dispatch = useAppThunkDispatch();
  const [freezeModalOpen, setFreezeModalOpen] = useState(false);
  const [isChangingMasjid, setIsChangingMasjid] = useState(false);
  const [selectedMasjid, setSelectedMasjid] = useState("");
  const [isMasjidChanged, setIsMasjidChanged] = useState(false);
  const [sendInvitationModal, setSendInvitationModal] = useState(false);

  const [masjidsList, setMasjidsList] = useState<Masjid[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const { masjids, loading, error } = useSearchMasjids(searchQuery);

  const getCooldownForAdmin = (adminId: string) => cooldowns[adminId] || 0;

  useEffect(() => {
    console.log("searching masjids");
    if (!loading && !error) {
      if (masjids?.length > 0) {
        setMasjidsList(masjids);
      } else {
        console.log("masjids lists blank");
        setMasjidsList([]);
      }
      // Update the list with fetched masjids
    } else if (!loading && error) {
      setMasjidsList([]);
    }
  }, [masjids, loading, error]);

  const debouncedSearchMasjids = useCallback(
    debounce((query) => {
      setSearchQuery(query);
    }, 300),
    [searchQuery]
  );

  const handleSearchMasjid = (query: string) => {
    debouncedSearchMasjids(query);
  };

  const handleMasjidSelection = (masjid: string) => {
    console.log(
      !selectedAdmin?.masjidDetails?.[0]?.masjidId,
      selectedMasjid,
      selectedMasjid !== selectedAdmin?.masjidDetails?.[0]?._id
    );
    // console.log(selectedAdmin.masjid);
    setSelectedMasjid(masjid);
    if (
      (!selectedAdmin?.masjidDetails?.[0]?.masjidId && masjid) ||
      selectedMasjid !== selectedAdmin?.masjidDetails?.[0]?._id
    ) {
      setIsMasjidChanged(true);
    } else {
      setIsMasjidChanged(false);
    }
  };
  const handleSubmitMasjid = async () => {
    console.log(isMasjidChanged, selectedMasjid);
    try {
      if (
        isMasjidChanged &&
        selectedMasjid !== selectedAdmin?.masjidDetails?.[0]?._id
      ) {
        let toastid = toast.loading("Updating Masjid...");
        if (selectedMasjid) {
          // console.log("result.data => ", result.data);
          const result = await handleUpdationAdmin(
            selectedAdmin._id,
            selectedMasjid
          );
          toast.dismiss(toastid);
          if (result) {
            toast.success("Masjid Updated Successfully");
            setOpen(false);
            refetch();
          } else {
            toast.error("Failed to Update Masjid");
          }
        } else if (!selectedMasjid) {
          const isUserDeleted = await dispatch(
            DeleteMasjidInAdminById({ user: selectedAdmin?._id ?? "" })
          );

          if (isUserDeleted.success) {
            toast.dismiss(toastid);
            toast.success("Masjid Updated Successfully");
            setOpen(false);
            refetch();
          } else {
            toast.error("Failed to Update Masjid");
          }
        }
      }
    } catch (err) {
      toast.dismiss();

      console.log("Error Updating Masjid", err);
      toast.error("Error Updating Masjid");
    }

    setIsChangingMasjid(false);
  };
  const handleCancelMasjid = () => {
    setIsMasjidChanged(false);
    setIsChangingMasjid(false);
    setSelectedMasjid(selectedAdmin.masjid);
  };

  const handleUpdationAdmin = async (
    addingUserId: string,
    selectedMasjid: string
  ) => {
    let uploadData = {
      user: addingUserId,
      masjidId: selectedMasjid,
    };

    try {
      const result = await dispatch(UpdateMasjidInAdminById(uploadData));
      return result.success;
    } catch (error) {
      console.error("Error updating admin:", error);
      return false;
    }
  };
  const handleSendInvitation = () => {
    if (selectedAdmin?.email) {
      toast.loading("Sending Invitation...");
      const response = dispatch(ReSendingReinvitation(selectedAdmin.email));
      response.then(function (result: any) {
        if (result.success) {
          toast.dismiss();
          const snackbarSuccessDetails = {
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Sent the email successfully",
          };
          dispatch(ChangeSnackbar(snackbarSuccessDetails));
          setSendInvitationModal(false);
          setCooldowns((prev) => {
            const newCooldowns = { ...prev };
            newCooldowns[selectedAdmin._id] = 30;
            return newCooldowns;
          });
        } else {
          toast.dismiss();
          const snackbarFailureDetails = {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed to send the email",
          };
          dispatch(ChangeSnackbar(snackbarFailureDetails));
          setSendInvitationModal(false);
        }
      });
    }
  };
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        // flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Dialog
        open={sendInvitationModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to send an invitation again ?"}
        </DialogTitle>
        <DialogActions style={{ justifyContent: "space-around" }}>
          <Button
            onClick={() => {
              setSendInvitationModal(false);
            }}
            variant="outlined"
            // disabled={isFreezing}
          >
            No
          </Button>
          <Button
            onClick={() => {
              handleSendInvitation();
            }}
            // disabled={isFreezing}
            variant="outlined"
          >
            {
              // isFreezing ? <CircularProgress size="15px" /> :
              <span> Yes </span>
            }
          </Button>
        </DialogActions>
      </Dialog>
      {isLoading ? (
        <CircularProgress />
      ) : showReasonConfirmation ? (
        // <ReasonConfirmation
        //   onConfirm={(status: string) => {
        //     if (!reason) {
        //       setReasonError(true);
        //       return;
        //     }
        //     handleSubmit(status);
        //   }}
        //   reason={reason}
        //   setReason={setReason}
        //   reasonError={reasonError}
        //   setReasonError={setReasonError}
        //   isLoading={isLoading}
        // />
        <ReasonConfirmation
          onConfirm={(status: string) => {
            if (!reason) {
              setReasonError(true);
              return;
            }
            handleSubmit(status);
          }}
          reason={reason}
          setReason={setReason}
          reasonError={reasonError}
          setReasonError={setReasonError}
          isLoading={isLoading}
          status={
            adminStatus === AdminStatus.DEACTIVE ? "approving" : "freezing"
          }
        />
      ) : showLogs ? (
        <StatusLogs logs={logs} />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            marginTop: "12px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flex: "auto",
          }}
        >
          <Box>
            <>
              {getAdminStatus(
                selectedAdmin?.isFreezed,
                selectedAdmin?.isVerified
              ) === "rejected" && (
                <>
                  <Typography variant="subtitle2" sx={{ mt: 1 }}>
                    Status
                  </Typography>

                  <Typography variant="body2" sx={getValueFieldStyles("")}>
                    Freezed
                  </Typography>
                </>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant="subtitle2" sx={{ mt: 1 }}>
                    Role
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={getValueFieldStyles(selectedAdmin?.role)}
                  >
                    {roleRenamer(selectedAdmin?.role ?? "")}
                  </Typography>
                </Box>
                {/* {adminStatus !== AdminStatus.DEACTIVE && (
                  <Box
                    component={"img"}
                    src={EditIcon}
                    sx={{ width: "12.5px", cursor: "pointer" }}
                    onClick={() => {
                      setIsEditing(true);
                    }}
                  />
                )} */}
              </Box>

              {/* {isChangingRole && (
                  <Dropdown
                    label={roleRenamer(selectedAdmin?.role) || "Data Entry"}
                    options={[
                      {
                        label: "Data Entry",
                        value: "admin",
                      },
                      {
                        label: "Masjid Admin",
                        value: "subadmin",
                      },
                      {
                        label: "Musali Admin",
                        value: "musaliadmin",
                      },
                    ]}
                    onSelect={handleRoleSelection}
                    error={false}
                    selectedValue={selectedRole || selectedAdmin?.role}
                    isMasjidChanged={isRoleChanged}
                    handleSubmitMasjid={handleSubmitRole}
                    handleCancelMasjid={handleCancelRole}
                  ></Dropdown>
                )} */}
              {selectedAdmin?.role !== "admin" &&
                selectedAdmin?.role !== "superadmin" && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ width: "75%" }}>
                        <Typography variant="subtitle2" sx={{ mt: 1 }}>
                          Assigned Masjid
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            ...getValueFieldStyles(
                              Array.isArray(selectedAdmin?.masjidDetails) &&
                                selectedAdmin?.masjidDetails?.length > 0
                                ? selectedAdmin?.masjidDetails[0].masjidName
                                : ""
                            ),
                            ...(selectedAdmin?.masjidDetails?.length > 0 &&
                              !selectedAdmin?.isApproved && {
                                color: "#F7A400",
                              }),
                          }}
                        >
                          {!isChangingMasjid &&
                            (selectedAdmin?.masjidDetails?.length > 0
                              ? !selectedAdmin.isApproved
                                ? `Pending (${selectedAdmin?.masjidDetails[0].masjidName})`
                                : selectedAdmin?.masjidDetails[0].masjidName
                              : "Not Assigned")}
                        </Typography>
                      </Box>
                      {adminStatus !== AdminStatus.DEACTIVE &&
                        !isChangingMasjid && (
                          <Button
                            variant="contained"
                            sx={{
                              width: "105px",
                              borderRadius: "12px",
                              bgcolor: "#1B8368",
                              "&:hover": { bgcolor: "#1B8368" },
                              textTransform: "none",
                              padding: "3px",
                            }}
                            onClick={() => {
                              toast.dismiss();
                              if (loadingMasjids) {
                                // toast.("Loading Masjids...");
                                setTimeout(() => {
                                  toast.dismiss();
                                }, 1000);
                                return;
                              }
                              setIsChangingMasjid(true);
                            }}
                          >
                            {loadingMasjids ? "loading..." : "Change"}
                            {loadingMasjids ? (
                              <CircularProgress
                                sx={{
                                  color: "white",
                                  marginLeft: "5px",
                                }}
                                size={"20px"}
                              ></CircularProgress>
                            ) : (
                              <Box
                                component={"img"}
                                src={ChangeIcon}
                                sx={{ width: "20px", marginLeft: "5px" }}
                              />
                            )}
                            {/* <Box
                              component={"img"}
                              src={ChangeIcon}
                              sx={{ width: "20px", marginLeft: "5px" }}
                            /> */}
                          </Button>
                        )}
                    </Box>

                    {isChangingMasjid && masjidsList && (
                      // <SearchDropdownChange
                      //   options={masjidsList || []}
                      //   placeholder="Type To Search Masjid."
                      //   onInputChange={(query) => {
                      //     if (query === "") {
                      //       setSelectedMasjid("");
                      //     }

                      //     handleSearchMasjid(query);

                      //     // handleSearchMasjid?.(query);
                      //   }} // Call the search function
                      //   onSelect={(selectedOption: any) => {
                      //     console.log(selectedOption);
                      //     handleMasjidSelection(selectedOption);
                      //   }}
                      //   error={false}
                      //   selectedValue={selectedMasjid}
                      //   loading={loading}
                      //   clearData={() => {
                      //     setSelectedMasjid("");
                      //   }}
                      //   handleSubmitMasjid={handleSubmitMasjid}
                      //   handleCancelMasjid={handleCancelMasjid}
                      // />

                      <Box mt={2}>
                        <Dropdown
                          loadingOptions={loading}
                          label={
                            Array.isArray(selectedAdmin?.masjidDetails) &&
                            selectedAdmin?.masjidDetails?.length > 0
                              ? selectedAdmin?.masjidDetails[0]?.masjidName
                              : "Select a Masjid"
                          }
                          options={
                            masjidsList?.length > 0
                              ? [
                                  ...masjidsList.map((masjid: Masjid) => ({
                                    label: masjid.masjidName,
                                    value: masjid._id,
                                  })),
                                ]
                              : []
                            // : [{ label: "Select a Masjid", value: "" }]
                            // dataMasjids?.getMasjids &&
                            // Array.isArray(dataMasjids.getMasjids) &&
                            // dataMasjids?.getMasjids.length > 0
                            //   ? [
                            //       { label: "Select a Masjid", value: "" },
                            //       ...dataMasjids.getMasjids
                            //         .slice()
                            //         .sort((a, b) =>
                            //           a.masjidName.localeCompare(b.masjidName)
                            //         )
                            //         .map((masjid: Masjid) => ({
                            //           label: masjid.masjidName,
                            //           value: masjid._id,
                            //         })),
                            //     ]
                            //   : [{ label: "Select a Masjid", value: "" }]
                          }
                          onSelect={handleMasjidSelection}
                          error={false}
                          selectedValue={
                            selectedMasjid
                              ? selectedMasjid
                              : Array.isArray(selectedAdmin?.masjidDetails) &&
                                selectedAdmin.masjidDetails.length > 0
                              ? selectedAdmin?.masjidDetails[0]._id
                              : selectedAdmin?.masjidDetails?.length === 0
                              ? ""
                              : ""
                          }
                          onInputChange={(query: string) => {
                            handleSearchMasjid(query);
                          }}
                          isMasjidChanged={isMasjidChanged}
                          handleSubmitMasjid={handleSubmitMasjid}
                          handleCancelMasjid={handleCancelMasjid}
                        ></Dropdown>
                      </Box>
                    )}
                    {/* {isChangingMasjid && (
                      <Box mt={2}>
                        <Dropdown
                          loadingOptions={loadingMasjids}
                          label={
                            Array.isArray(selectedAdmin?.masjidDetails) &&
                            selectedAdmin?.masjidDetails?.length > 0
                              ? selectedAdmin?.masjidDetails[0]?.masjidName
                              : "Select a Masjid"
                          }
                          options={
                            dataMasjids?.getMasjids &&
                            Array.isArray(dataMasjids.getMasjids) &&
                            dataMasjids?.getMasjids?.length > 0
                              ? [
                                  { label: "Select a Masjid", value: "" },
                                  ...dataMasjids.getMasjids.map(
                                    (masjid: Masjid) => ({
                                      label: masjid.masjidName,
                                      value: masjid._id,
                                    })
                                  ),
                                ]
                              : [
                                  { label: "Select a Masjid", value: "" },
                                  // {
                                  //   label: "Islamic Center of Irving",
                                  //   value: "Islamic Center of Irving",
                                  // },
                                  // {
                                  //   label: "East Plano Islamic Center",
                                  //   value: "East Plano Islamic Center",
                                  // },
                                  // {
                                  //   label: "Madina Masjid",
                                  //   value: "Madina Masjid",
                                  // },
                                  // {
                                  //   label: "ICQC Masjid",
                                  //   value: "ICQC Masjid",
                                  // },
                                ]
                          }
                          onSelect={handleMasjidSelection}
                          error={false}
                          selectedValue={
                            selectedMasjid
                              ? selectedMasjid
                              : Array.isArray(selectedAdmin?.masjidDetails) &&
                                selectedAdmin.masjidDetails.length > 0
                              ? selectedAdmin?.masjidDetails[0]._id
                              : selectedAdmin?.masjidDetails?.length === 0
                              ? ""
                              : ""
                          }
                          isMasjidChanged={isMasjidChanged}
                          handleSubmitMasjid={handleSubmitMasjid}
                          handleCancelMasjid={handleCancelMasjid}
                        ></Dropdown>
                      </Box>
                    )} */}
                  </>
                )}
              <Typography variant="subtitle2" sx={{ mt: 2 }}>
                Full Name
              </Typography>
              <Typography
                variant="body2"
                sx={getValueFieldStyles(selectedAdmin?.name)}
              >
                {selectedAdmin?.name ?? ""}
              </Typography>
              <Typography variant="subtitle2" sx={{ mt: 2 }}>
                Email Address
              </Typography>

              <Typography
                variant="body2"
                sx={getValueFieldStyles(selectedAdmin?.email)}
              >
                {selectedAdmin?.email ?? ""}
              </Typography>
            </>

            {/* {(adminStatus === AdminStatus.ACTIVE ||
              adminStatus === AdminStatus.DEACTIVE) && ( */}

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ width: "50%" }}>
                <Typography
                  variant="subtitle2"
                  sx={{ mt: 2, textTransform: "capitalize" }}
                >
                  Created At
                </Typography>
                <Typography
                  variant="body2"
                  sx={getValueFieldStyles(selectedAdmin?.createdAt)}
                >
                  {selectedAdmin?.createdAt
                    ? moment
                        .utc(selectedAdmin.createdAt)
                        .local()
                        .format("DD MMM, YYYY | hh:mm A")
                    : "Not Available"}
                </Typography>
              </Box>

              <Box sx={{ width: "50%" }}>
                <Typography
                  variant="subtitle2"
                  sx={{ mt: 2, textTransform: "capitalize" }}
                >
                  Last Updated At
                </Typography>
                <Typography
                  variant="body2"
                  sx={getValueFieldStyles(selectedAdmin?.updatedAt)}
                >
                  {selectedAdmin?.updatedAt
                    ? moment
                        .utc(selectedAdmin.updatedAt)
                        .local()
                        .format("DD MMM, YYYY | hh:mm A")
                    : "Not Available"}
                </Typography>
              </Box>
            </Box>
            {/* )} */}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "12px",
              mt: 3,
              gap: 2,
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              {adminStatus === AdminStatus.ACTIVE ||
              adminStatus === AdminStatus.UNVERIFIED ? (
                <Button
                  variant="contained"
                  sx={{
                    width: "200px",
                    borderRadius: "20px",
                    bgcolor: "#FF2D30",
                    "&:hover": { bgcolor: "#FF2D30" },
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setShowReasonConfirmation(true);
                    // setFreezeModalOpen(true);
                  }}
                >
                  Freeze Admin
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    width: "200px",
                    borderRadius: "20px",
                    bgcolor: "#00D23B",
                    "&:hover": { bgcolor: "#00D23B" },
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setShowReasonConfirmation(true);
                    // setFreezeModalOpen(true);
                  }}
                >
                  Unfreeze Admin
                </Button>
              )}
              {selectedAdmin &&
                !selectedAdmin.isVerified &&
                adminStatus !== AdminStatus.DEACTIVE && (
                  <Button
                    variant="contained"
                    sx={{
                      width: "200px",
                      borderRadius: "20px",
                      bgcolor: "#1B8368",
                      "&:hover": { bgcolor: "#1B8368" },
                      textTransform: "none",
                      "&.Mui-disabled": {
                        cursor: "not-allowed !important",
                        color: "#686868",
                      },
                    }}
                    onClick={() => {
                      setSendInvitationModal(true);
                      // handleSendInvitation();
                      // toast.success("Invitation Sent");
                      // setSeconds(30);
                    }}
                    disabled={getCooldownForAdmin(selectedAdmin?._id) > 0}
                  >
                    <Box
                      component={"img"}
                      src={EmailFilledIcon}
                      sx={{ width: "20px", marginRight: "5px" }}
                    />
                    Send Invitation{" "}
                    {getCooldownForAdmin(selectedAdmin?._id) > 0 &&
                      `(${getCooldownForAdmin(selectedAdmin?._id)}s)`}
                  </Button>
                )}
            </div>
            <Button
              variant="text"
              sx={{
                color: "#3D5347",
                textDecoration: "underline",
                textTransform: "none",
                fontSize: "13.5px",
                width: "150px",
                fontWeight: "600",
                "&:hover": { textDecoration: "underline" },
              }}
              onClick={() => {
                setShowLogs(true);
              }}
            >
              Check Logs
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default ExternalAdminHandler2;
