import React, { useEffect, useState } from "react";
import "./SideBar.css";
import { FaMosque } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { authLogout } from "../../../Redux/Actions/AuthActions/LogoutAction";
import { AiOutlineLogout } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { MdOutlineFontDownloadOff } from "react-icons/md";
import { CgPlayListRemove } from "react-icons/cg";
import { GrAnnounce } from "react-icons/gr";
import Masjidlogo from "../../../Photos/logomasjid.png";
import { RootState } from "../../../Redux/Store";
// import veri1 from "../../../Photos/Newuiphotos/VerificationCenter/veri1.png";
import veri1 from "../../../Photos/Newuiphotos/VerificationCenter/veri11.svg";
import veri11black from "../../../Photos/Newuiphotos/VerificationCenter/veri11grey.svg";
import externaladmins from "../../../Photos/Newuiphotos/Admins/externaladminsblack.svg";
import internaladmins from "../../../Photos/Newuiphotos/Admins/internaladminsblack.png";
import internaladminswhite from "../../../Photos/Newuiphotos/Admins/internaladmins.png";
import { Box } from "@mui/material";
import PackageJson from "../../../../package.json";
const SideBar = () => {
  interface Admin {
    role: string;
  }
  const sidebarState = useSelector((state: RootState) => state.sidebarState);

  const handleLogout = () => {
    authLogout();
    localStorage.clear();
    window.location.reload();
  };
  const adminString = localStorage.getItem("admin");
  const admin: Admin | null = adminString ? JSON.parse(adminString) : null;
  const navigate = useNavigate();
  const [isHoveredOn, setIsHoveredOn] = useState("");

  return (
    <>
      <div className="SideBarContainer">
        <div className="SideBarTop">
          <Link to="/" className="LinkMasjid">
            <img
              src={Masjidlogo}
              alt="connectmasjidlogo"
              className="masjidLogoImage"
            />
          </Link>
        </div>
        <div className="SideBarBottom">
          {sidebarState ? (
            <>
              <ul className="SideBarMenu">
                <li
                  className="SideBarItem"
                  onClick={() => navigate("/masjids")}
                >
                  <FaMosque className="MosqueIcon2" />
                  Masjids
                </li>

                {admin?.role === "superadmin" ? (
                  <>
                    <li
                      className="SideBarItem"
                      onClick={() => navigate("/freezeMasjids")}
                    >
                      <CgPlayListRemove className="StaleMosqueIcon2" />
                      Freezed Masjids
                    </li>
                  </>
                ) : null}
                {admin?.role === "superadmin" ? (
                  <>
                    {/* <li
                      className="SideBarItem"
                      onClick={() => navigate("/admins")}
                    >
                      <BsFillPersonFill className="MosqueIcon" />
                      Admins
                    </li> */}

                    {/* <li
                      className="SideBarItem"
                      onClick={() => navigate("/freezeAdmins")}
                    >
                      <MdOutlineFontDownloadOff className="StaleMosqueIcon" />
                      Freezed Admin
                    </li> */}
                    {/* <li
                      className="SideBarItem"
                      onClick={() => navigate("/announcements")}
                    >
                      <GrAnnounce className="StaleMosqueIcon" />
                      Announcement
                    </li> */}

                    <li
                      className="SideBarItem"
                      onClick={() => navigate("/verification-center")}
                      onMouseEnter={() => setIsHoveredOn("verification-center")} // Change state on hover
                      onMouseLeave={() => setIsHoveredOn("")} // Revert state when not hovering
                    >
                      {/* <GrAnnounce className="StaleMosqueIcon" /> */}
                      <Box
                        className="StaleMosqueIcon"
                        component="img"
                        src={
                          isHoveredOn === "verification-center"
                            ? veri1
                            : veri11black
                        } // Set the image based on hover state
                        sx={{
                          width: "25px",
                          // height: "20px",
                          // cursor: "pointer", // Optional: Adds a pointer on hover
                        }}
                      />
                      Payment Center
                    </li>

                    <li
                      className="SideBarItem"
                      onClick={() => navigate("/external-admins")}
                      onMouseEnter={() => setIsHoveredOn("external-admins")} // Change state on hover
                      onMouseLeave={() => setIsHoveredOn("")} // Revert state when not hovering
                    >
                      {/* <GrAnnounce className="StaleMosqueIcon" /> */}
                      <Box
                        className="StaleMosqueIcon"
                        component="img"
                        src={
                          isHoveredOn === "external-admins"
                            ? externaladmins
                            : externaladmins
                        } // Set the image based on hover state
                        sx={{
                          width: "25px",
                          // height: "20px",
                          // cursor: "pointer", // Optional: Adds a pointer on hover
                        }}
                      />
                      External Admins
                    </li>

                    <li
                      className="SideBarItem"
                      onClick={() => navigate("/internal-admins")}
                      onMouseEnter={() => setIsHoveredOn("internal-admins")} // Change state on hover
                      onMouseLeave={() => setIsHoveredOn("")} // Revert state when not hovering
                    >
                      <Box
                        className="StaleMosqueIcon"
                        component="img"
                        src={
                          isHoveredOn === "internal-admins"
                            ? internaladminswhite
                            : internaladmins
                        }
                        sx={{
                          width: "25px",
                        }}
                      />
                      Internal Admins
                    </li>
                  </>
                ) : (
                  <></>
                )}

                <li className="SideBarItem" onClick={handleLogout}>
                  <AiOutlineLogout className="MosqueIcon" />
                  Logout
                </li>
              </ul>
            </>
          ) : (
            <>
              <ul className="SideBarMenuClose">
                <li className="SideBarItemClose">
                  <Link to="/masjids" className="LinkMasjidClose">
                    <FaMosque className="MosqueIcon2" />
                  </Link>
                </li>

                {admin?.role === "superadmin" || admin?.role === "admin" ? (
                  <>
                    <li className="SideBarItemClose">
                      <Link
                        to="/admins"
                        className="LinkMasjidClose"
                        style={{ textDecoration: "none" }}
                      >
                        <BsFillPersonFill
                          className="MosqueIcon"
                          style={{ textDecoration: "none" }}
                        />
                      </Link>
                    </li>
                    <li className="SideBarItemClose">
                      <Link to="/freezeMasjids" className="LinkMasjidClose">
                        <CgPlayListRemove className="StaleMosqueIcon3" />
                      </Link>
                    </li>
                    <li className="SideBarItemClose">
                      <Link to="/freezeAdmins" className="LinkMasjidClose">
                        <MdOutlineFontDownloadOff className="MosqueIcon2" />
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                {admin?.role === "superadmin" ? (
                  <>
                    <li className="SideBarItemClose">
                      <Link to="/announcements" className="LinkMasjidClose">
                        <GrAnnounce className="MosqueIcon" />
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}

                <li className="SideBarItemClose" onClick={handleLogout}>
                  <AiOutlineLogout className="MosqueIcon" />
                </li>
              </ul>
            </>
          )}
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "12px",
            color: "grey",
            padding: "5px",
          }}
        >
          v{PackageJson.version}
        </div>
      </div>
    </>
  );
};

export default SideBar;
