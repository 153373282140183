import {
  Box,
  Button,
  Card,
  IconButton,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { ChangeEvent, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  dummyInternalData,
  useExternalUsers,
  useInternalUsers,
} from "../../../graphql/Admins/query";
import {
  AdminStatus,
  getAdminStatus,
  getFormattedAdminStatus,
} from "../helpers";
import InternalAdminStatushandler from "./InternalAdminStatushandler";
import { roleRenamer } from "../../../helpers/RoleRenamer";
import addAdmin from "../../../Photos/Newuiphotos/Admins/addAdmin.png";
import InternalAdminForm from "./InternalAdminForm";
import toast from "react-hot-toast";
import { useAppThunkDispatch } from "../../../Redux/hooks";
import { AddingNewAdmin } from "../../../Redux/Actions/AdminActions/AddingNewAdmin";
import { ChangeSnackbar } from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import { AddingNewAdminWithDescription } from "../../../Redux/Actions/AdminActions/AddingNewAdminWithDesc";
import { useMutation } from "@apollo/client";
import { USER_MASJID_ACTION } from "../../../graphql/Admins/mutation";
import moment from "moment";
const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .no-rows-primary": {
    fill: "#3D4751",
    ...theme.applyStyles("light", {
      fill: "#AEB8C2",
    }),
  },
  "& .no-rows-secondary": {
    fill: "#1D2126",
    ...theme.applyStyles("light", {
      fill: "#E8EAED",
    }),
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={96}
        viewBox="0 0 452 257"
        aria-hidden
        focusable="false"
      >
        <path
          className="no-rows-primary"
          d="M348 69c-46.392 0-84 37.608-84 84s37.608 84 84 84 84-37.608 84-84-37.608-84-84-84Zm-104 84c0-57.438 46.562-104 104-104s104 46.562 104 104-46.562 104-104 104-104-46.562-104-104Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 113.929c3.905-3.905 10.237-3.905 14.142 0l63.64 63.64c3.905 3.905 3.905 10.236 0 14.142-3.906 3.905-10.237 3.905-14.142 0l-63.64-63.64c-3.905-3.905-3.905-10.237 0-14.142Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 191.711c-3.905-3.906-3.905-10.237 0-14.142l63.64-63.64c3.905-3.905 10.236-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142l-63.64 63.64c-3.905 3.905-10.237 3.905-14.142 0Z"
        />
        <path
          className="no-rows-secondary"
          d="M0 10C0 4.477 4.477 0 10 0h380c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 20 0 15.523 0 10ZM0 59c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 69 0 64.523 0 59ZM0 106c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 153c0-5.523 4.477-10 10-10h195.5c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 200c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 247c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10Z"
        />
      </svg>
      <Box sx={{ mt: 2 }}>No rows</Box>
    </StyledGridOverlay>
  );
}
const InternalAdminsTable = () => {
  const dispatch = useAppThunkDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [logDetails, setLogDetails] = useState();

  const [searchText, setSearchText] = useState("");
  const [selectedAdminId, setSelectedAdminId] = useState("");
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [cooldowns, setCooldowns] = useState({});
  useEffect(() => {
    // Set up an interval that runs every second
    const timerId = setInterval(() => {
      // Decrement each active admin’s countdown
      setCooldowns((prev) => {
        const newCooldowns = {};

        // Loop over each adminId in prev
        Object.keys(prev).forEach((adminId) => {
          const secondsLeft = prev[adminId];
          // If there's more than 1 second left, decrement.
          // If it hits 1 → 0, we simply omit that admin from newCooldowns
          if (secondsLeft > 1) {
            newCooldowns[adminId] = secondsLeft - 1;
          }
        });

        return newCooldowns;
      });
    }, 1000);

    // Cleanup on unmount
    return () => clearInterval(timerId);
  }, []);
  const [
    updateAdminMasjid,
    {
      data: updateAdminMasjidData,
      loading: updateAdminMasjidLoading,
      error: updateAdminMasjidError,
    },
  ] = useMutation(USER_MASJID_ACTION);
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleActionClick = (action: string, details: any) => {
    console.log(action);
    console.log(details);
    setModalOpen(true);
    setSelectedAdminId(details._id);
    setSelectedAdmin(details);
  };
  const { data, loading, error, refetch } = useInternalUsers(false);

  const filteredData =
    data?.length > 0
      ? data
          .filter(
            (row) => {
              const searchTerm = searchText.trim().toLowerCase();

              const adminStatus = getFormattedAdminStatus(
                getAdminStatus(row?.isFreezed, row?.isVerified)
              ).toLowerCase();
              return (
                row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                row.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                roleRenamer(row.role)
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()) ||
                adminStatus.includes(searchTerm.toLowerCase())
              );
            }
            // row.status === selectedStatus &&
          )
          .sort((a, b) => {
            const groupA = getGroupPriority(a);
            const groupB = getGroupPriority(b);

            // Compare group priority first
            if (groupA !== groupB) {
              return groupA - groupB;
            }

            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
          })
      : [];
  function getGroupPriority(item) {
    if (!item.isFreezed && !item.isVerified) {
      return 1; // Group 1: isFreezed = false, isApproved or isVerified = false
    }
    if (!item.isFreezed && item.isVerified) {
      return 2; // Group 2: isFreezed = false, isApproved and isVerified = true
    }
    return 3; // Group 3: isFreezed = true
  }
  const handleAddAdmin = async (
    name: string,
    email: string,
    role: string,
    selectedMasjid: string
  ) => {
    // if (tempAdmin) {
    let updatedData = {
      name: name,
      email: email.toLowerCase(),
      role: role,
      description: "",
    };
    console.log(updatedData, selectedMasjid, "");

    try {
      const toastId = toast.loading("Adding Admin...");
      const result = await dispatch(AddingNewAdminWithDescription(updatedData));
      console.log("result", result);
      if (!result._id) {
        console.log("error occured");
        toast.dismiss(toastId);
        return;
      } else {
        const snackbarSuccessDetails = {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Admin credentials have been sent to the admin",
        };
        dispatch(ChangeSnackbar(snackbarSuccessDetails));
        toast.dismiss(toastId);
        refetch();
      }
    } catch (err) {
      console.error("Error adding admin:", error);
      const snackbarFailureDetails = {
        snackbarOpen: true,
        snackbarType: "error",
        snackbarMessage: "Error adding admin",
      };
      toast.dismiss();
      dispatch(ChangeSnackbar(snackbarFailureDetails));
    }
    // }
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: 3,
      }}
    >
      <Card sx={{ mt: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            fullWidth
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon sx={{ fontSize: "25px" }} />
                </IconButton>
              ),
            }}
            sx={{
              margin: "20px",
              width: "80%",
              borderRadius: "30px", // Add this line to increase the border radius
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Add shadow here
              borderColor: "#565656",
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px", // Ensure the input field's border radius is updated
              },
              "& .MuiOutlinedInput-notchedOutline": {
                // borderColor: "#565656",
              },
            }}
            variant="outlined"
          />

          <Button
            variant="outlined"
            sx={{
              color: "#1B8368",
              borderColor: "#1B8368",
              borderWidth: "2px",
              height: "fit-content",
              borderRadius: "20px",
              textTransform: "none",
              fontWeight: "bold",
              marginRight: "20px",
              textWrap: "nowrap",
              padding: "8px 12px",
              width: "auto",
              ":hover": {
                borderColor: "#1B8368",
                borderWidth: "2px",
              },
            }}
            onClick={() => {
              setSelectedAdmin(null);
              setShowAddForm(true);
            }}
          >
            <Box
              component="img"
              src={addAdmin}
              sx={{ width: "20px", marginRight: "8px" }}
            />
            Add New Admin
          </Button>
        </Box>
        <div style={{ height: loading ? "650px" : "1200px", width: "100%" }}>
          <DataGrid
            loading={loading}
            rows={filteredData}
            columns={[
              { field: "name", headerName: "Name", flex: 1 },
              { field: "email", headerName: "Email", flex: 1 },
              // { field: "contact", headerName: "Contact", flex: 1 },
              {
                field: "role",
                headerName: "Role",
                flex: 1,
                valueGetter: (params: any) => {
                  const value = params.row.role;
                  return roleRenamer(value);
                },
              },
              {
                field: "status",
                headerName: "Status",
                flex: 1,
                valueGetter: (params: any) => {
                  const value = getFormattedAdminStatus(
                    getAdminStatus(params.row.isFreezed, params.row.isVerified)
                  );
                  return value;
                },
                renderCell: (params: any) => {
                  let color;
                  const value = getAdminStatus(
                    params.row.isFreezed,
                    params.row.isVerified
                  );
                  // Check the role and set the color accordingly
                  if (value === "approved" || value === null) {
                    color = "#1B8368";
                  } else if (value === "pending") {
                    color = "#EDA920";
                  } else {
                    color = "#FF4949";
                  }
                  return (
                    <Typography
                      style={{
                        color,
                        cursor: "pointer",
                        textTransform: "capitalize",
                      }}
                      variant="body2"
                      onClick={() => {
                        handleActionClick(value, params.row);
                      }}
                    >
                      {getFormattedAdminStatus(
                        getAdminStatus(
                          params.row.isFreezed,
                          params.row.isVerified
                        )
                      )}
                    </Typography>
                  );
                },
              },
              {
                field: "createdAt",
                headerName: "Created At",
                flex: 1,
                // width: 150,
                // hideable: true,
                // hide: true,
                valueGetter: (params: any) => {
                  const value = params.row.createdAt;
                  return moment.utc(value).format("DD-MMM-YYYY HH:mm A");
                },
              },
              // {
              //   field: "masjid",
              //   headerName: "Managed Masjid",
              //   flex: 1,
              //   renderCell: (params: any) => {
              //     let color;
              //     const value = getAdminStatus(
              //       params.row.isFreezed,
              //       params.row.isVerified
              //     );
              //     // Check the role and set the color accordingly
              //     if (value === "approved" || value === null) {
              //       color = "#1B8368";
              //     } else if (value === "pending") {
              //       color = "#EDA920";
              //     } else {
              //       color = "#A9A9A9";
              //     }
              //     return (
              //       <Typography
              //         style={{
              //           color,
              //           cursor: "pointer",
              //           textTransform: "capitalize",
              //           whiteSpace: "wrap",
              //           textAlign: "center",
              //           // margin: params.row?.masjid===""?"auto":""
              //         }}
              //         variant="body2"
              //         onClick={() => {
              //           handleActionClick(value, params.row);
              //         }}
              //       >
              //         {params.row?.masjid === "" ? "-:-" : params.row?.masjid}
              //       </Typography>
              //     );
              //   },
              // },
            ]}
            pageSize={20}
            rowsPerPageOptions={[20]}
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            disableSelectionOnClick
            getRowId={(row) => row._id}
            // getRowClassName={params =>
            //   params.indexRelativeToCurrentPage % 2 === 0
            //     ? "even-row"
            //     : "odd-row"}
            getRowClassName={(params) => {
              return getAdminStatus(
                params.row?.isFreezed,
                params.row?.isVerified
              ) === "rejected"
                ? "frozen-masjid-row"
                : "";
            }}
            sx={{
              "& .MuiDataGrid-virtualScroller": {
                // Always show vertical scrollbar
                overflowY: "auto",
                // overflowX: "",
                // WebKit-based styling for the scrollbar track, thumb, etc.
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#f0f0f0",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#bfbfbf",
                  borderRadius: "8px",
                },
                "&:hover::-webkit-scrollbar-thumb": {
                  backgroundColor: "#8f8f8f",
                },
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-cell": {
                "&:hover": {
                  outline: "none",
                },
                userSelect: "text",
              },
            }}
          />
        </div>
        <InternalAdminStatushandler
          cooldowns={cooldowns}
          setCooldowns={setCooldowns}
          selectedAdmin={selectedAdmin}
          setSelectedAdmin={setSelectedAdmin}
          open={modalOpen}
          onClose={() => {}}
          setOpen={setModalOpen}
          isLoading={false}
          refetch={refetch}
        />
        <InternalAdminForm
          open={showAddForm}
          setOpen={setShowAddForm}
          onClose={() => {
            setShowAddForm(false);
          }}
          handleSubmit={(name, email, role, selectedMasjid) => {
            setShowAddForm(false);
            handleAddAdmin(name, email, role, selectedMasjid);
          }}
          adminType="internal"
        />
      </Card>
    </Box>
  );
};

export default InternalAdminsTable;
