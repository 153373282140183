import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import { getFreezedMasjids } from "../../../Redux/Actions/MasjidActions/FetchingFreezedMasjid";
import { ChangeSnackbar } from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import "./FreezeMasjidTable.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress } from "@material-ui/core";
import { useAppThunkDispatch } from "../../../Redux/hooks";
import { Masjid } from "../../../../Types";
import { FreezeMasjidStatus } from "../../../Redux/Actions/MasjidActions/FreezingMasjid";
import { GET_FREEZED_MASJIDS } from "../../../graphql/queries";
import { useMutation, useQuery } from "@apollo/client";
import { FREEZE_MASJID } from "../../../graphql/mutations";
import toast from "react-hot-toast";
import ReasonConfirmation from "../../Shared/ReasonConfirmation/ReasonConfirmation";
import { Box, DialogContent } from "@mui/material";
import { setQuarter } from "date-fns";
import freezeMasjidIcon from "../../../Photos/Newuiphotos/MasjidTable/FreezeMasjid.png";
import { Colors } from "../../../constants/Colors/colors";
import { useUpdateMasjidStatus } from "../../../graphql/Masjids/mutation";
const today = moment();

const FreezeMasjidTable = () => {
  // not require now may be required later
  // const currentAdmin = useSelector((state) => state.currentAdmin);
  const dispatch = useAppThunkDispatch();
  const [FreezedMasjids, setFreezedMasjids] = useState<Masjid[]>([]);
  const [isUnFreezing, setisUnFreezing] = useState(false);
  const [FreezeModalOpen, setFreezeModalOpen] = useState(false);
  const [UnfreezingMasjid, setUnfreezingMasjid] = useState<Masjid | undefined>(
    undefined
  );
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(false);

  const { loading, error, data, refetch } = useQuery(GET_FREEZED_MASJIDS);

  const {
    updateStatus,
    loading: statusLoading,
    error: statusError,
    data: statusData,
  } = useUpdateMasjidStatus();

  useEffect(() => {
    if (!loading && error) {
      toast.error("Error Fetching Freezed Masjids");
    }
  }, [loading, error, data]);
  const handleSubmit = async (status: string, reason: string) => {
    if (!reason || !status || !UnfreezingMasjid?._id) return;
    const freeze = status === "approved" ? false : true;
    try {
      await updateStatus({
        masjidId: UnfreezingMasjid?._id ?? "",
        description: reason,
        status,
        freeze,
      });
      toast.success("Successfully Updated Masjid Status");
      setReason("");
      setReasonError(false);
      refetch();
      setFreezeModalOpen(false);
    } catch (err) {
      console.error("Error submitting form:", err);
      toast.error("Error Updating Masjid Status");
    }
  };

  const handleModelOpen = (adminDetails: any) => {
    setFreezeModalOpen(true);
    setUnfreezingMasjid(adminDetails.row);
  };
  const userColumns: GridColDef[] = [
    {
      field: "masjidName",
      headerName: "Masjid Name",
      //autoComplete: "off",
      width: 280,
    },
    {
      field: "updatedAt",
      headerName: "Last Updated On",
      //autoComplete: "off",
      width: 220,
      renderCell: (params: any) => {
        return (
          <div className="dataUpdatedAt">
            {moment(params.row.updatedAt).format("MM/DD/YYYY hh:mm a")}
          </div>
        );
      },
    },
    {
      field: "Days Before",
      headerName: "Days since last update",
      //autoComplete: "off",
      width: 100,
      renderCell: (params: any) => {
        return (
          <div>
            {moment(today.format()).diff(moment(params.row.updatedAt), "days")}
          </div>
        );
      },
    },
    {
      field: "lastEditor",
      headerName: "Last Updated By",
      width: 200,
      editable: false,
      renderCell: (params: any) => {
        return <div>{params.row?.updatedBy?.name}</div>;
      },
    },
    {
      field: "contact",
      headerName: "Contact",
      //autoComplete: "off",
      width: 170,
    },
    {
      field: "address",
      headerName: "Address",
      width: 240,
      //autoComplete: "off",
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleModelOpen(params);
            }}
            style={{
              border: "1px solid green",
              color: "green",
              height: "20px",
              fontSize: "10px",
              cursor: "Pointer",
            }}
          >
            UnFreeze
          </Button>
        );
      },
    },
  ];

  // useEffect(() => {
  //   const response = dispatch(getFreezedMasjids());
  //   response.then(function (result: any) {
  //     if (result.success) {
  //       setFreezedMasjids(result.masjids);
  //     } else {
  //       const snackbarFailureDetails = {
  //         snackbarOpen: true,
  //         snackbarType: "error",
  //         snackbarMessage: result.message
  //           ? "Failed to Freeze Masjid Details : " + result.message
  //           : "Failed to Freeze Masjid Details  : Internet or Server Issue ",
  //       };
  //       dispatch(ChangeSnackbar(snackbarFailureDetails));
  //     }
  //   });
  // }, []);

  const handleCloseUnFreezeModal = () => {
    setReasonError(false);
    setReason("");
    setFreezeModalOpen(false);
  };

  const [freezeMasjid, { data: fData, loading: fLoading, error: fError }] =
    useMutation(FREEZE_MASJID, {
      refetchQueries: [{ query: GET_FREEZED_MASJIDS }],
      awaitRefetchQueries: true,
    });
  const handleFreezeMasjid = async (masjidId: string) => {
    try {
      const response = await freezeMasjid({
        variables: {
          id: masjidId,
          isFreezed: false,
        },
      });
      toast.success("Successfully Unfreezed masjid");
      setFreezeModalOpen(false);
    } catch (err) {
      toast.error("Error unfreezing masjid:", err as any);
      setFreezeModalOpen(false);
    }
  };

  return (
    <div className="FreezedMasjidTableContainer">
      <Dialog
        onClose={handleCloseUnFreezeModal}
        open={FreezeModalOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "22px",
            padding: "12px",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            textAlign: "center",
            color: Colors.greenBlack,
            fontSize: "16px",
            fontWeight: "600",
            padding: "10px",
          }}
        >
          {/* {`Are you sure you want to Unfreeze the Masjid ?`} */}
          {/* {showFinalConfirmation ? (
            <img
              src={freezeMasjidIcon}
              alt="Freeze Masjid"
              style={{
                width: "45px",
              }}
            />
          ) : ( */}
          Reason To Unfreeze
          {/* )} */}
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            overflowX: "hidden",
            minHeight: "400px",
          }}
        >
          {/* {showFinalConfirmation ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Are You Sure You Want to UnFreeze this masjid?
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "40%",
                }}
                mt={2}
              >
                <Button
                  sx={{
                    bgcolor: "#F93737",
                    "&:hover": {
                      bgcolor: "#F93737", // Changes background to a slightly darker red on hover
                    },
                    color: "#fff",
                    borderRadius: " 12px",
                    padding: "6px 25px",
                  }}
                  onClick={() => {
                    setShowFinalConfirmation(false);
                    setFreezeModalOpen(true);
                  }}
                >
                  No
                </Button>
                <Button
                  sx={{
                    bgcolor: "#1B8368",
                    color: "#fff",
                    borderRadius: " 12px",
                    padding: "6px 25px",
                    "&:hover": {
                      bgcolor: "#1B8368", // Changes background to a slightly darker red on hover
                    },
                  }}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          ) : ( */}
          <ReasonConfirmation
            onConfirm={(status) => {
              if (!reason) {
                setReasonError(true);
                return;
              }
              handleSubmit(status, reason);
              // setFreezeModalOpen(false);
              // setShowFinalConfirmation(true);

              // console.log("status change to ", status);
            }}
            newStatus={"approved"}
            reason={reason}
            setReason={setReason}
            reasonError={reasonError}
            setReasonError={setReasonError}
            isLoading={statusLoading}
          />
          {/* )} */}
        </DialogContent>
        {/* <DialogActions style={{ justifyContent: "space-around" }}>
          <Button
            onClick={handleUnFreezeModal}
            variant="outlined"
            disabled={fLoading}
          >
            No
          </Button>
          <Button
            onClick={() => handleFreezeMasjid(UnfreezingMasjid?._id)}
            variant="outlined"
          >
            {" "}
            {fLoading ? (
              <CircularProgress
                //  color="black"
                size="15px"
              />
            ) : (
              <span> Yes </span>
            )}{" "}
          </Button>
        </DialogActions> */}
      </Dialog>
      <div
        style={{
          height: 700,
          width: "100%",
          marginTop: "1.2rem",
          backgroundColor: "white",
          borderRadius: "20px",
        }}
      >
        <DataGrid
          sx={{
            "& .MuiDataGrid-cell": {
              userSelect: "text",
            },
          }}
          loading={loading}
          rows={
            !loading
              ? data?.getFreezedMasjids?.length > 0
                ? data?.getFreezedMasjids
                : []
              : []
          }
          // rows={FreezedMasjids?.length > 0 ? FreezedMasjids : []}
          columns={userColumns}
          pageSize={10}
          getRowId={(row: Masjid) => row._id}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
    </div>
  );
};

export default FreezeMasjidTable;
